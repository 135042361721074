<template>
  <div class="page">
    <div v-if="categoryIsLoad" class="category-box">
      <!-- 选择类目 -->
      <div class="category-item">
        <div class="category-key">选择类目:</div>
        <div class="category-val flex-column">
          <div class="category-input">{{ categoryActiveStr }}</div>
          <b-goods-category
            class="m-t-5"
            :ids="categoryRouteIds"
            :isInit="!categoryRouteIds.length"
            @success="getCategoryActiveList"
          />
        </div>
      </div>

      <!-- 材质维护 -->
      <div v-if="categoryLastId" class="category-item">
        <div class="category-key">材质维护:</div>
        <div class="category-val">
          <ModuleCaizhi
            :list.sync="caizhiList"
            :obj="categoryActiveObj"
            @success="getModuleCaizhiList"
          />
        </div>
      </div>

      <!-- 计量属性维护 -->
      <div v-if="categoryLastId" class="category-item">
        <div class="category-key">计量属性维护:</div>
        <div class="category-val">
          <ModuleGuige
            :list.sync="guigeList"
            :obj="categoryActiveObj"
            @success="getModuleGuigeList"
          />
        </div>
      </div>

      <!-- 单位维护 -->
      <div v-if="categoryLastId" class="category-item">
        <div class="category-key">单位维护:</div>
        <div class="category-val">
          <ModuleUnit
            :list.sync="unitList"
            :guigeList="guigeList"
            :obj="categoryActiveObj"
            @success="getModuleUnitList"
          />
        </div>
      </div>

      <!-- 单位转换检测 -->
      <div v-if="categoryLastId" class="category-item">
        <div class="category-key">单位转换检测:</div>
        <div class="category-val">
          <ModuleTran :obj="categoryActiveObj" :caizhiList="caizhiList" :guigeList="guigeList" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModuleCaizhi from './components/module-caizhi'
import ModuleGuige from './components/module-guige'
import ModuleUnit from './components/module-unit'
import ModuleTran from './components/module-tran'
export default {
  components: {
    ModuleCaizhi,
    ModuleGuige,
    ModuleUnit,
    ModuleTran
  },
  data() {
    return {
      // 类目
      categoryIsLoad: false,
      categoryLastId: '',
      categoryRouteIds: [],
      categoryActiveList: [],

      // 模块
      caizhiList: [], // 材质
      guigeList: [], // 规格
      unitList: [] // 单位
    }
  },
  computed: {
    // 类目字符串
    categoryActiveStr() {
      let result = ''
      let listName = this.categoryActiveList.map((item) => item.name)
      result = listName.join(' / ')
      return result // 类目名称拼接成字符串
    },
    // 类目末级选中
    categoryActiveObj() {
      let result = ''
      let len = this.categoryActiveList.length
      if (len) result = this.categoryActiveList[len - 1]
      return result
    }
  },
  watch: {
    categoryActiveObj(newVal, oldVal) {
      if (newVal.id !== oldVal.id) {
        if (newVal.has_children) {
          this.categoryLastId = ''
        } else {
          this.categoryLastId = newVal.id
          this.$nextTick(() => {
            this.initModuleData()
          })
        }
      }
    }
  },
  mounted() {
    this.getCategoryRouteIds()
  },
  methods: {
    // 获取路由类目ids
    getCategoryRouteIds() {
      this.categoryIsLoad = false
      let idsStr = this.$route.query.ids
      let idsList = []
      if (idsStr) idsList = idsStr.split(',')
      this.categoryRouteIds = idsList.map((item) => Number(item))
      this.categoryIsLoad = true
    },
    // 获取选中类目列表
    getCategoryActiveList(list) {
      this.categoryActiveList = []
      list.forEach((itemSt) => {
        itemSt.category.forEach((itemNd) => {
          if (itemSt.title.active_id === itemNd.id) {
            this.categoryActiveList.push(this.$util.deepCopy(itemNd))
          }
        })
      })
    },
    // 初始化模块数据
    initModuleData() {
      this.getModuleCaizhiList()
      this.getModuleGuigeList()
      this.getModuleUnitList()
    },
    // 获取模块-材质列表
    getModuleCaizhiList() {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/midu/list',
        params: {
          id: this.categoryLastId
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.caizhiList = res.data.list
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 获取模块-规格列表
    getModuleGuigeList() {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/shuxing/list',
        params: {
          id: this.categoryLastId
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.guigeList = res.data.list
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 获取模块-单位列表
    getModuleUnitList() {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/columnDataJiSuanGongShi/list',
        params: {
          id: this.categoryLastId
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.unitList = res.data.list
        } else {
          this.$message.error(res.data.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  padding-bottom: 10px;
  .category-box {
    .category-item {
      display: flex;
      & ~ .category-item {
        margin-top: 5px;
      }
      .category-key {
        width: 107px;
        padding: 5px 5px 5px 0;
        line-height: 28px;
        text-align: right;
        background: #f8f8f9;
        border: 1px solid #e4e7ed;
        border-radius: 2px 0 0 2px;
      }
      .category-val {
        min-width: 806px;
        padding: 5px;
        margin-left: -1px;
        border: 1px solid #e4e7ed;
        border-radius: 0px 2px 2px 0;
        .category-input {
          width: 793px;
          height: 26px;
          line-height: 26px;
          padding: 0 5px;
          border: 1px solid #ddd;
          border-radius: 2px;
        }
      }
    }
  }

  /deep/ .iconfont {
    cursor: pointer;
    & ~ .iconfont {
      margin-left: 5px;
    }
  }

  /deep/ .vxe-table--body-wrapper {
    min-height: 30px !important;
    max-height: 152px !important;
  }

  /deep/ .row--hover {
    background: @colorBlue10;
  }
}
</style>
