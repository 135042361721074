<template>
  <div class="module-guige">
    <div class="module-first">
      <el-button type="primary" size="mini" plain @click="importBatch">批量引用</el-button>
      <el-button type="success" size="mini" @click="listSave">保存计量属性</el-button>
    </div>
    <vxe-grid v-bind="tableOptions" :data="selfList">
      <template #default_name="{ row }">
        <el-input size="mini" clearable v-model="row.name" @blur="inputBlur(row)" />
      </template>
      <template #default_val="{ row }">
        <el-input size="mini" clearable v-model="row.val" @blur="inputBlur(row)" />
      </template>
      <template #default_bol="{ row }">
        <span v-if="row.order_id" class="c-green">是</span>
      </template>

      <template #default_operate="{ rowIndex }">
        <span class="iconfont icon-add" title="增行" @click="itemAdd(rowIndex)" />
        <span class="iconfont icon-delete" title="删行" @click="itemDelete(rowIndex)" />
      </template>
    </vxe-grid>
    <div class="module-demo">
      <span :class="demoStr ? 'm-r-10' : ''">规格表示示例：{{ demoStr }}</span>
      <el-link v-if="effectList.length" type="primary" @click="guigeDemoEdit">编辑</el-link>
    </div>
    <PopupImport
      v-if="isShowPopupImport"
      :show.sync="isShowPopupImport"
      :obj="obj"
      @success="importConfirm"
    />
    <PopupGuigeDemo
      v-if="isShowPopupGuigeDemoEdit"
      :show.sync="isShowPopupGuigeDemoEdit"
      :list="effectList"
      @success="guigeDemoUpdate"
    />
  </div>
</template>

<script>
import PopupImport from './popup-import-guige'
import PopupGuigeDemo from './popup-guige-demo'
export default {
  components: {
    PopupImport,
    PopupGuigeDemo
  },
  props: {
    list: {
      type: Array,
      required: true
    },
    obj: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selfList: [
        {
          name: '',
          val: '',
          order_id: ''
        }
      ],
      tableOptions: {
        size: 'mini',
        border: true,
        'highlight-hover-row': true,
        columns: [
          { title: '计量属性名称', field: 'name', width: 201, slots: { default: 'default_name' } },
          { title: '计量属性值', field: 'val', width: 201, slots: { default: 'default_val' } },
          { title: 'id', field: 'id', width: 201 },
          { title: '是否运用示例', width: 101, slots: { default: 'default_bol' } },
          { title: '操作', align: 'right', slots: { default: 'default_operate' } }
        ]
      },
      isShowPopupImport: false,
      isShowPopupGuigeDemoEdit: false
    }
  },
  computed: {
    // 有效计量属性列表
    effectList() {
      return this.selfList.filter((item) => {
        return item.id && item.name
      })
    },
    // 规格示例字符串
    demoStr() {
      let listGuige = this.selfList.filter((item) => {
        return item.id && item.name && item.order_id
      })
      let sortListGuige = listGuige.sort((a, b) => a.order_id - b.order_id) // 排序
      let listKey = sortListGuige.map((item) => item.name)
      let listVal = sortListGuige.map((item) => item.val)
      let strKey = listKey.join('*')
      let strVal = listVal.join('*')
      let result = ''
      if (strKey) result = `${strKey} = ${strVal}`
      return result
    }
  },
  watch: {
    list: {
      handler(val) {
        if (val.length) {
          if (JSON.stringify(this.selfList) !== JSON.stringify(val)) {
            this.selfList = this.$util.deepCopy(val)
          }
        } else {
          this.selfList = [
            {
              name: ''
            }
          ]
        }
      },
      deep: true,
      immediate: true
    },
    selfList: {
      handler(val) {
        if (JSON.stringify(this.list) !== JSON.stringify(val)) {
          this.$emit('update:list', this.$util.deepCopy(val))
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 项-新增
    itemAdd(index) {
      this.selfList.splice(index + 1, 0, {
        name: ''
      })
    },
    // 项-删除
    async itemDelete(index) {
      let { id, order_id } = this.selfList[index]
      let isFail = false
      if (id) {
        if (order_id) {
          this.$message.error('该计量属性在规格表示示例中使用，不能删除')
          return
        }
        await this.$api({
          method: 'get',
          url: '/admin/gcvip/shuxing/delete',
          params: {
            id
          }
        })
          .then((res) => {
            if (res.data.state === 'ok') {
              this.$message.success('删除成功')
            } else {
              this.$message.error(res.data.msg)
              isFail = true
            }
          })
          .catch((err) => {
            this.$message.error(err.data.msg)
            isFail = true
          })
      }
      if (isFail) return
      if (this.selfList.length > 1) {
        this.selfList.splice(index, 1)
      } else {
        this.selfList[index].name = ''
      }
    },
    // 引用-批量
    importBatch() {
      this.isShowPopupImport = true
    },
    // 引用-确认
    importConfirm(importList) {
      let result = []
      importList.forEach((importItem) => {
        let isRepeat = this.selfList.some((selfItem) => {
          return selfItem.name === importItem.name
        })
        if (!isRepeat) {
          result.push({
            name: importItem.name,
            val: importItem.val
          })
        }
      })
      if (result.length) {
        let effectList = this.selfList.filter((item) => {
          return item.name
        })
        this.selfList = [...effectList, ...result]
      }
      this.listSave()
    },
    // 输入框-失焦
    inputBlur(row) {
      if (row.name && row.val) this.listSave()
    },
    // 列表-保存
    listSave() {
      let effectList = this.selfList.filter((item) => {
        return item.name
      })
      let pushList = effectList.map((item) => {
        return {
          ...item,
          column_data_id: this.obj.id
        }
      })
      this.$api({
        method: 'post',
        url: '/admin/gcvip/shuxing/saveOrUpdateByList',
        data: {
          list: pushList
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.$message.success('保存成功')
          this.$emit('success')
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 规格-案例-编辑
    guigeDemoEdit() {
      this.isShowPopupGuigeDemoEdit = true
    },
    // 规格-案例-更新
    guigeDemoUpdate(effectList) {
      let selfList = this.$util.deepCopy(this.selfList)
      selfList.forEach((selfItem, index) => {
        effectList.forEach((effectItem) => {
          if (selfItem.id === effectItem.id) {
            let result = this.$util.deepCopy(effectItem)
            selfList.splice(index, 1, result)
          }
        })
      })
      this.selfList = this.$util.deepCopy(selfList)
      this.listSave()
    }
  }
}
</script>

<style lang="less" scoped>
.module-guige {
  .module-first {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 28px;
    margin-bottom: 5px;
  }
  .module-demo {
    display: flex;
    align-items: center;
    padding: 5px;
    line-height: 20px;
    border: 1px solid #ddd;
    border-top: none;
  }
}
</style>
