<template>
  <div class="module-tran">
    <div class="module-item">
      <div class="module-key">材质密度:</div>
      <div class="module-val">
        <el-select size="mini" v-model="caizhi" @change="check">
          <el-option
            v-for="(item, index) in selfCaizhiList"
            :key="index"
            :label="item.key"
            :value="item.cai_zhi"
          />
        </el-select>
      </div>
    </div>
    <div class="module-item">
      <div class="module-key">计量属性:</div>
      <div class="module-val guige-list">
        <div class="guige-item" v-for="(item, index) in selfGuigeList" :key="index">
          <el-input
            style="width: 134px"
            size="mini"
            :placeholder="`请输入${item.name}`"
            v-model="item.val"
          >
            <span slot="suffix">{{ item.name }}</span>
          </el-input>
        </div>
      </div>
    </div>
    <div class="module-item">
      <div class="module-key">检测操作:</div>
      <div class="module-val">
        <el-button size="mini" type="warning" @click="check">马上检测</el-button>
      </div>
    </div>
    <div class="module-item">
      <div class="module-key">检测结果:</div>
      <div class="module-val">
        <div class="tran-item" v-for="(item, index) in tranList" :key="index">
          <i v-if="index">=</i>
          <el-input size="mini" disabled :value="item.value">
            <span slot="suffix">{{ item.name }}</span>
          </el-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    obj: {
      type: Object,
      required: true
    },
    caizhiList: {
      type: Array,
      required: true
    },
    guigeList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      caizhi: '',
      selfGuigeList: [],
      tranList: []
    }
  },
  computed: {
    // 材质列表
    selfCaizhiList() {
      let result = []
      this.caizhiList.forEach((item) => {
        if (item.id && item.cai_zhi && item.val) {
          let obj = {
            key: `${item.cai_zhi}(${item.val})`,
            ...item
          }
          result.push(obj)
        }
      })
      return result
    }
  },
  watch: {
    // 材质列表
    caizhiList: {
      handler(val) {
        if (val.length) {
          this.caizhi = val[0].cai_zhi
        } else {
          this.caizhi = ''
        }
      },
      deep: true,
      immediate: true
    },
    // 规格列表
    guigeList: {
      handler(val) {
        let result = val.filter((item) => {
          return item.id && item.name
        })
        this.selfGuigeList = this.$util.deepCopy(result)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    check() {
      let query = this.selfGuigeList.map((item) => {
        return {
          name: item.name,
          val: item.val
        }
      })
      let params = {
        id: this.obj.id,
        cai_zhi: this.caizhi,
        query
      }
      this.$api({
        method: 'post',
        url: '/admin/gcvip/columnDataJiSuanGongShi/doJiSuan',
        data: params
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            this.tranList = res.data.list.filter((item) => item.is_ok)
            this.$message.success('检测成功')
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch(() => {
          this.$message.error('检测失败')
        })
    }
  }
}
</script>

<style lang="less" scoped>
.module-tran {
  .module-item {
    display: flex;
    border: 1px solid #e4e7ed;
    & ~ .module-item {
      margin-top: -1px;
    }
    .module-key {
      width: 107px;
      padding: 5px 5px 5px 0;
      line-height: 28px;
      text-align: right;
      background: #f8f8f9;
    }
    .module-val {
      flex: 1;
      display: flex;
      padding: 5px;
      border-left: 1px solid #e4e7ed;
      &.guige-list {
        flex-wrap: wrap;
        padding: 5px 0 0 5px;
        .guige-item {
          display: flex;
          margin: 0 5px 5px 0;
        }
      }
    }
  }
  .tran-item {
    flex: 1;
    display: flex;
    align-items: center;
    /deep/ .el-input {
      flex: 1;
    }
    /deep/ .el-input__suffix {
      color: @colorGray;
    }
    i {
      font-style: normal;
      padding: 0 5px;
      color: @colorGray;
    }
  }
  /deep/ .el-input {
    width: 134px;
  }
  /deep/ .el-input__suffix {
    line-height: 26px;
  }
  /deep/ .el-button {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 26px;
  }
}
</style>
